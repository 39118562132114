import { IconName } from '@zenchef/icomoon'
import { Flex, Stack } from '@zenchef/styled-system/jsx'
import { token } from '@zenchef/styled-system/tokens'
import { addMonths, format } from 'date-fns'
import { reaction } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { useSearchParams } from 'next/navigation'
import { useRouter } from 'next/router'
import { useCallback, useContext, useEffect, useState } from 'react'

import { MainLayout, RestaurantComment } from '@/components'
import { CalendarPicker } from '@/components/DatePicker'
import AccordionBlock from '@/components/redesign/common/Accordion/AccordionBlock'
import AccordionItem from '@/components/redesign/common/Accordion/AccordionItem'
import Subtitle from '@/components/redesign/common/Subtitle'
import WelcomeBooking from '@/components/redesign/ebm/WelcomeBooking'
import AreaInput from '@/components/redesign/filters/AreaInput'
import GuestInput from '@/components/redesign/filters/GuestInput'
import MoreInfoAboutDate from '@/components/redesign/filters/MoreInfoAboutDate'
import NoAvailabilityForCurrentRoom from '@/components/redesign/filters/NoAvailabilityForCurrentRoom'
import SearchBookingWishButton from '@/components/redesign/filters/SearchBookingWishButton'
import SlotInput from '@/components/redesign/filters/SlotInput/SlotInput'
import useSlotSelector from '@/components/redesign/filters/SlotInput/useSlotSelector'
import WarningsForSelectedFilters from '@/components/redesign/filters/WarningsForSelectedFilters'
import HighlightedExperiencesCarousel from '@/components/redesign/offers/HighlightedExperiencesCarousel'
import Loader from '@/components/ui/Loader'
import { ANALYTICS_EVENTS_NAMES, fireAnalyticEvent } from '@/lib/GoogleAnalytics'
import { Bookings, isWishFilter, NextPageWithLayout, WishFilter } from '@/types/types'
import { lookingForAvailabilitiesMaxMonths } from '@/utils/constants'
import getDateFnsLocal from '@/utils/getDateFnsLocal'
import { useTranslation } from '@/utils/hooks'
import StoresContext from '@/utils/StoresContext'
import { getShortDateFormat } from '@/utils/useFormattedDateAndTime'

const ResultsPage: NextPageWithLayout = observer(function () {
  const { appStore } = useContext(StoresContext)

  const router = useRouter()

  const searchParams = useSearchParams()
  const currentFilter = searchParams.get('currentFilter')

  const [currentAccordionItem, setCurrentAccordionItem] = useState<WishFilter | undefined>(
    currentFilter && isWishFilter(currentFilter) ? currentFilter : undefined
  )

  const { t } = useTranslation()
  const {
    wish,
    shouldLoadSuggestedRestaurants,
    isInUpdateFlow,
    isModificationForbidden,
    isCurrentDayAvailableForCurrentPax,
    isLoading,
    isCurrentDayOpen
  } = appStore.state
  const localStore = useLocalObservable(() => ({
    hasLoadedNextAvailabilities: false
  }))
  const filtersDisabled = isModificationForbidden

  const { hasSlotsToSelect, formatSlotName } = useSlotSelector()

  useEffect(() => {
    async function effect() {
      if (shouldLoadSuggestedRestaurants) {
        if (!appStore.state.suggestedAppStoresInitialized) {
          await appStore.createSuggestedRestaurantStores()
        }
        appStore.loadSuggestedRestaurantsData()
      }
    }
    effect()
  }, [shouldLoadSuggestedRestaurants, wish.pax, wish.day, appStore])

  const getNextAvailabilities = (next = 1) => {
    if (next >= lookingForAvailabilitiesMaxMonths) return
    const nextMonth = addMonths(wish.day, next).getMonth() + 1
    const yearOfNextMonth = addMonths(wish.day, next).getFullYear()
    appStore.getAvailabilitiesSummary(nextMonth, yearOfNextMonth).then(() => {
      if (appStore.state.openDays.length < 10) {
        getNextAvailabilities(next + 1)
      }
    })
  }

  useEffect(() => {
    return reaction(
      () => [appStore.state.isLoading, appStore.state.isCurrentDayOpen, appStore.state.openDays.length],
      () => {
        if (!appStore.state.isLoading && !appStore.state.isCurrentDayOpen && !appStore.state.currentMonth) {
          const [year, month] = appStore.state.currentMonthKey.split('-')
          appStore.getAvailabilitiesSummary(month, year)
        } else if (
          !appStore.state.isLoading &&
          appStore.state.openDays.length < 10 &&
          !localStore.hasLoadedNextAvailabilities
        ) {
          localStore.hasLoadedNextAvailabilities = true
          getNextAvailabilities()
        }
      },
      { fireImmediately: true }
    )
  }, [])

  useEffect(() => {
    return () => {
      if (searchParams.has('currentFilter')) {
        const params = new URLSearchParams(searchParams)
        params.delete('currentFilter')
        router.push({ query: params.toString() })
      }
    }
  }, [searchParams, router])

  const locale = getDateFnsLocal(appStore.state.language)
  const minPax = appStore.state.shift_limit ? parseInt(appStore.state.shift_limit.min || '1') : 1
  const maxPax = appStore.state.shift_limit ? parseInt(appStore.state.shift_limit.max || '12') : 12

  const resetSlot = () => {
    // if wish.slot is not available reset the slot
    const { selectedShift, getAvailableSlots } = appStore.state
    if (
      !selectedShift ||
      !getAvailableSlots(selectedShift).length ||
      !getAvailableSlots(selectedShift).find((slot) => slot.slot_name === wish.slot)
    ) {
      wish.slot = undefined
    }
  }

  const resetRoom = () => {
    // if wish.room_id is not available reset the room
    if (wish.room_id && !appStore.state.currentDayBookableRooms.find((r) => r.id === wish.room_id)) {
      wish.room_id = null
    }
  }

  const onSelectGuest = (nbGuest: number, isMoreGuest: boolean = false) => {
    wish.pax = nbGuest
    resetRoom()
    resetSlot()

    fireAnalyticEvent(ANALYTICS_EVENTS_NAMES.CHOOSE_PAX, {
      pax: wish.pax
    })

    if (isMoreGuest) {
      return
    }

    if (!appStore.state.isCurrentDayAvailableForCurrentPax) {
      setCurrentAccordionItem('calendar')
      return
    }

    if (appStore.state.hasRoomSelection && !wish.room_id) {
      setCurrentAccordionItem('area')
    } else {
      setCurrentAccordionItem(wish.slot ? undefined : 'slot')
    }
  }

  const handleMonthChange = (date) => {
    const d = new window.Date(date)
    const m = d.getMonth() + 1
    const y = d.getFullYear()
    appStore.getAvailabilitiesSummary(m, y)
    const followingMonth = addMonths(d, 1)
    const m2 = followingMonth.getMonth() + 1
    const y2 = followingMonth.getFullYear()
    appStore.getAvailabilitiesSummary(m2, y2)
  }

  const handleDaySelected = () => {
    appStore.getCommentSpecific(wish.day)

    resetRoom()
    resetSlot()

    fireAnalyticEvent(ANALYTICS_EVENTS_NAMES.CHOOSE_DATE, {
      date: wish.day
    })
  }

  const handleOpenCalendar = useCallback(() => {
    const [year, month] = appStore.state.currentMonthKey.split('-')
    if (appStore.state.currentMonth?.partial) {
      appStore.getAvailabilitiesSummary(month, year)
    }

    fireAnalyticEvent(ANALYTICS_EVENTS_NAMES.OPEN_CALENDAR)
  }, [appStore])

  return (
    <Stack data-panda-theme='whiteLabel' gap='gap.4' py='padding.4'>
      <WelcomeBooking />

      {!isInUpdateFlow ? (
        <>
          <RestaurantComment />
          <HighlightedExperiencesCarousel />
        </>
      ) : null}

      <Stack gap='gap.0'>
        <AccordionBlock currentItem={currentAccordionItem} setCurrentItem={setCurrentAccordionItem}>
          <AccordionItem
            title={`${wish.pax} ${t('pax', { count: wish.pax })}`}
            icon={t('pax_icon') as IconName}
            placeholder={t('filter.pax.placeholder')}
            id='pax'
            testId='pax'
            disabled={filtersDisabled}
            fixedHeight>
            <GuestInput
              min={minPax}
              max={maxPax}
              selectedValue={wish.pax}
              onSelectValue={(value, isMoreGuest) => {
                onSelectGuest(value, isMoreGuest)
              }}
            />
          </AccordionItem>
          <AccordionItem
            title={
              appStore.state.isDateToday(wish.day)
                ? t('global.today')
                : format(wish.day, getShortDateFormat(appStore.state.language), {
                    locale
                  })
            }
            id='calendar'
            testId='calendar'
            placeholder={t('filter.date.placeholder')}
            icon='calendar'
            onOpen={handleOpenCalendar}
            fixedHeight={isCurrentDayAvailableForCurrentPax}
            maxHeight='600px'
            disabled={filtersDisabled}>
            <Stack justify='center' gap='gap.4' pb='padding.4'>
              <CalendarPicker
                privatisation={false}
                handleMonthChange={handleMonthChange}
                handleDaySelected={handleDaySelected}
                close={() => {
                  if (appStore.state.hasRoomSelection && !wish.room_id) {
                    setCurrentAccordionItem('area')
                  } else {
                    setCurrentAccordionItem(wish.slot ? undefined : 'slot')
                  }
                }}
              />
              <MoreInfoAboutDate handleDaySelected={handleDaySelected} />
            </Stack>
          </AccordionItem>
          {appStore.state.isCurrentDayOpen && appStore.state.hasRoomSelection ? (
            <AccordionItem
              title={
                appStore.state.selectedRoomName ??
                (appStore.state.isRoomMandatory ? undefined : t('no_seating_preference'))
              }
              icon='chair'
              placeholder={t('filter.room.placeholder')}
              id='area'
              testId='area'
              disabled={filtersDisabled}
              fixedHeight>
              {appStore.state.isRoomMandatory ? (
                <Flex>
                  <Subtitle mb='margin.4'>{t('filter.room.mandatory.subtitle')}</Subtitle>
                </Flex>
              ) : null}
              <AreaInput
                options={appStore.state.currentDayBookableRooms}
                isRequired={appStore.state.isRoomMandatory}
                selectedValue={wish.room_id}
                onSelectValue={(room: Bookings.Room) => {
                  appStore.state.wish.room_id = room.id
                  resetSlot()
                  fireAnalyticEvent(ANALYTICS_EVENTS_NAMES.CHOOSE_ROOM, {
                    room: room.name,
                    room_id: room.id
                  })
                  setCurrentAccordionItem(wish.slot ? undefined : 'slot')
                }}
              />
            </AccordionItem>
          ) : null}
          {hasSlotsToSelect && (
            <AccordionItem
              id='slot'
              testId='slot'
              placeholder={t('filter.slot.placeholder')}
              icon='clock-01'
              scrollable
              hideBorder
              title={formatSlotName(wish.slot)}
              disabled={filtersDisabled}>
              {({ toggle }) => (
                <SlotInput
                  onSlotSelected={(slot, shift, _isWaitlist) => {
                    fireAnalyticEvent(ANALYTICS_EVENTS_NAMES.CHOOSE_SLOT, {
                      time: slot.name,
                      date: wish.day,
                      shift_name: shift.name
                    })
                    toggle()
                  }}
                />
              )}
            </AccordionItem>
          )}
        </AccordionBlock>
      </Stack>

      <Stack px='padding.4' textAlign='center' gap='gap.4'>
        {isModificationForbidden ? null : (
          <>
            {isCurrentDayOpen && <RestaurantComment specific />}
            <WarningsForSelectedFilters />
          </>
        )}

        <SearchBookingWishButton
          setCurrentAccordionItem={setCurrentAccordionItem}
          currentAccordionItem={currentAccordionItem}
        />
        {isLoading && !isCurrentDayOpen && <Loader bounceColor={token.var('colors.content.neutral.bold')} mx='auto' />}

        <NoAvailabilityForCurrentRoom />
      </Stack>
    </Stack>
  )
})

ResultsPage.getLayout = (page) => (
  <MainLayout
    modalBodyStyle={{
      backgroundColor: 'var(--colors-restaurant)'
    }}>
    {page}
  </MainLayout>
)

export default ResultsPage
