import { Carousel, Text } from '@zenchef/ds-react'
import { css } from '@zenchef/styled-system/css'
import { Stack } from '@zenchef/styled-system/jsx'
import { useContext } from 'react'

import HighlightedOfferCard from '@/components/redesign/offers/HighlightedOfferCard'
import { useTranslation } from '@/utils/hooks'
import StoresContext from '@/utils/StoresContext'

const HighlightedExperiencesCarousel = () => {
  const { appStore } = useContext(StoresContext)
  const { t } = useTranslation()
  const { highlightedOffers } = appStore.state

  if (!highlightedOffers.length) return null
  return (
    <Stack
      gap='gap.4'
      align='center'
      py='padding.6'
      _first={{
        pt: 0
      }}>
      <Text
        bg='background.neutral-on-brand.base.subtle'
        color='content.neutral.bold'
        px='padding.3'
        py='padding.1'
        textStyle='mono.s.regular'
        borderRadius='pill'>
        {t('recommended_for_you')}
      </Text>
      <Carousel dynamicPerView>
        {highlightedOffers.map((offer) => (
          <HighlightedOfferCard offer={offer} key={offer.id} />
        ))}
      </Carousel>
    </Stack>
  )
}

export default HighlightedExperiencesCarousel
