import BaseOfferCard from '@/components/redesign/offers/BaseOfferCard'
import { HighlightedOffer } from '@/types/types'
import { useTranslation } from '@/utils/hooks'

interface HighlightedOfferCardProps {
  offer: HighlightedOffer
}
const HighlightedOfferCard = ({ offer }: HighlightedOfferCardProps) => {
  const { translateField } = useTranslation()

  const offerName = translateField(offer.name_translations)
  const offerDescription = translateField(offer.description)

  return <BaseOfferCard offer={offer} offerName={offerName} offerDescription={offerDescription} />
}

export default HighlightedOfferCard
