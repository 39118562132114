import { Icon, Tag, Text, Tooltip } from '@zenchef/ds-react'
import { css } from '@zenchef/styled-system/css'
import { Box, Flex, HStack, Stack } from '@zenchef/styled-system/jsx'
import { token } from '@zenchef/styled-system/tokens'
import dynamic from 'next/dynamic'
import { useEffect, useState } from 'react'

import { Well } from '@/components'
import MoreLessOffer from '@/components/redesign/offers/MoreLessOffer'
import { Bookings, HighlightedOffer } from '@/types/types'
import { useTranslation } from '@/utils/hooks'
import isImageValid from '@/utils/isImageValid'
import safelySetInnerHTML from '@/utils/safelySetInnerHTML'

// React.lazy is buggy with SSR in React 18, and this is a workaround.
// TODO: remove this when React version is upgraded to 19
const ArtNoSSR = dynamic(() => import('@zenchef/ds-react').then((mod) => mod.Art), { ssr: false })

const isOfferSelected = (offer: Bookings.SelectedOffer | HighlightedOffer): offer is Bookings.SelectedOffer => {
  return 'has_specific_rooms' in offer
}

interface OfferTagData {
  colorTheme: 'brand' | 'neutral'
  tag: string
  tooltipTitle?: string
  tooltipDescription?: string
}

export interface BaseOfferCardProps {
  offer: HighlightedOffer | Bookings.SelectedOffer
  isAvailable?: boolean
  offerTags?: OfferTagData[]
  offerName: string
  offerDescription: string
  offerBookableRooms?: number[]
  hasRoomIncompatibility?: boolean
  roomsById?: Record<Bookings.Room['id'], Bookings.Room>
  hasStockTable?: boolean
  onCardClick?: (offerId: number) => void
  showMoreLess?: boolean
}

const OfferTag = ({ colorTheme, tag, tooltipTitle, tooltipDescription }: OfferTagData) => {
  const TagElement = () => (
    <Tag colorTheme={colorTheme} hierarchy='bold'>
      {tag}
    </Tag>
  )
  if (tooltipTitle || tooltipDescription) {
    return (
      <Tooltip title={tooltipTitle} description={tooltipDescription}>
        <TagElement />
      </Tooltip>
    )
  }
  return <TagElement />
}

const wrapperCss = css({
  flexWrap: 'wrap',
  border: 'none',
  boxShadow: 'effect.shadow.subtle-bottom',
  alignItems: 'stretch',
  maxWidth: 'calc(100vw - {spacing.padding.4})',
  width: {
    base: '304px',
    _containerS: '280px'
  },
  transition: 'transform 0.1s ease-in-out',
  _hover: {
    '&[data-clickable="true"]': {
      '&:not([aria-disabled="true"])': {
        cursor: 'pointer',
        boxShadow: 'effect.shadow.bolder-bottom',
        transform: 'translateY(-1px)',
        '&:not(:has(.less-button:hover)) .more-button, .add-button': {
          _enabled: {
            filter: {
              _dark: 'brightness(0.95)',
              _light: 'brightness(1.05)'
            }
          }
        }
      },
      _disabled: {
        cursor: 'not-allowed'
      }
    }
  },
  _active: {
    transition: 'none!',
    boxShadow: 'effect.shadow.subtle-bottom!',
    transform: 'translateY(0)!'
  }
})

const BaseOfferCard = ({
  offer,
  isAvailable,
  offerTags,
  offerName,
  offerDescription,
  offerBookableRooms = [],
  hasRoomIncompatibility = false,
  hasStockTable = false,
  roomsById,
  onCardClick,
  showMoreLess = false
}: BaseOfferCardProps) => {
  const { translateField } = useTranslation()

  const [isPictureUrlValid, setIsPictureUrlValid] = useState<boolean | undefined>(undefined)

  const pictureUrl =
    (isOfferSelected(offer) ? offer.picture_url : offer.picture?.url)?.replace(/(\.[a-z]+)$/i, '.csquarred$1') ??
    undefined

  useEffect(() => {
    if (pictureUrl) {
      isImageValid(pictureUrl).then((isValid) => {
        console.log('pictureUrl, isValid', pictureUrl, isValid)
        setIsPictureUrlValid(isValid)
      })
    }
  }, [pictureUrl])

  const backgroundStyle = pictureUrl ? `url(${pictureUrl})` : undefined

  const shouldDisplayPlaceholderImg = !backgroundStyle || !isPictureUrlValid

  return (
    <Well
      key={offer.id}
      aria-disabled={!!onCardClick && !isAvailable}
      data-clickable={!!onCardClick}
      className={wrapperCss}
      onClick={() => {
        if (onCardClick && isAvailable) {
          onCardClick(offer.id)
        }
      }}>
      <Stack
        justifyContent='flex-start'
        alignItems='flex-start'
        width='100%'
        filter={hasRoomIncompatibility ? 'grayscale(1)' : undefined}
        opacity={hasRoomIncompatibility ? 0.6 : undefined}
        gap='gap.0'>
        <Box
          height='[144px]'
          width='100%'
          flexShrink={0}
          borderTopRadius='l'
          backgroundPosition='center'
          backgroundColor='white'
          backgroundSize='cover'
          style={{
            backgroundImage: backgroundStyle
          }}
          position='relative'>
          {shouldDisplayPlaceholderImg ? (
            <Flex
              height='100%'
              width='100%'
              bgGradient='to-b'
              borderTopRadius='l'
              gradientFrom='background.neutral.base.bold/20'
              gradientTo='background.neutral.base.bold/0'
              color='content.brand.subtle'
              justifyContent='center'
              alignItems='center'>
              <ArtNoSSR name='Table' height='120px' width='120px' layout='square' />
            </Flex>
          ) : null}
          {!!offerTags?.length && (
            <HStack
              position='absolute'
              bottom='padding.3'
              left='padding.3'
              right='padding.3'
              gap='gap.1'
              flexWrap='wrap'>
              {offerTags.map((tagData) => (
                <OfferTag {...tagData} key={tagData.tag} />
              ))}
            </HStack>
          )}
          {isOfferSelected(offer) && showMoreLess ? (
            <MoreLessOffer
              big
              offer={offer}
              testId='offer-card'
              position='absolute'
              top='padding.3'
              right='padding.3'
              boxShadow='0px 1px 2px 0px token(colors.blackLevel.15)'
              isSelectable={isAvailable}
            />
          ) : null}
        </Box>

        <Stack p='padding.4' pt='padding.2' gap='gap.0' flexGrow={2} width='100%'>
          <HStack justify='space-between'>
            <Text textStyle='title.m' color='content.neutral.base.bold' lineClamp={2}>
              {offerName}
            </Text>
            <Tooltip title={offerName} description={offerDescription} placement='bottom-center'>
              <Icon name='info-circle' fontSize='20px' color='content.neutral.base.bold' />
            </Tooltip>
          </HStack>

          {offerDescription ? (
            <Text
              textStyle='paragraph.m.regular'
              color='content.neutral.base.subtle'
              {...safelySetInnerHTML(offerDescription)}
              overflow='hidden'
              textOverflow='ellipsis'
              display='-webkit-box'
              lineClamp={2}
              justifySelf='flex-start'
              boxOrient='vertical'
              flexGrow={1}
            />
          ) : null}
          {'has_specific_rooms' in offer && hasStockTable && roomsById && (
            <>
              <HStack
                gap='unset'
                pt='padding.2'
                mt='gap.2'
                columnGap='gap.2'
                rowGap='gap.0'
                borderTop='m'
                borderColor='border.neutral.subtle'
                alignItems='center'
                flexWrap='wrap'
                color='content.brand.subtle'>
                <Icon name='chair' fontSize={token('sizes.xs')} />
                {offerBookableRooms.map((roomId) => {
                  return (
                    <Text textStyle='title.s' key={roomId}>
                      {translateField(roomsById[roomId]?.name_translations)}
                    </Text>
                  )
                })}
              </HStack>
            </>
          )}
        </Stack>
      </Stack>
    </Well>
  )
}

export default BaseOfferCard
